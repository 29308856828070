import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppLogoNew } from '../../Asset/svg'
import { commonItemsSelector } from '../../redux/common';

const logoWidth = '100%';
const logoHeight = '80px';

function MaintenanceLayout() {
	const { appSetting } = useSelector(commonItemsSelector);
	const navigate = useNavigate()

	useEffect(() => {
		if(process.env.REACT_APP_ENV == 'prod'){
			if(appSetting?.items?.features?.website_status[0]?.key_value != "1"){
				navigate('/dashboard')
			}
		}
	}, [appSetting]);

  return (
    <Box style={{display: 'flex', justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
        <Box
            sx={{
                width: logoWidth,
                height: logoHeight,
                maxWidth: 400,
                marginBottom: 2
            }}
        >
            {/* <AppLogo /> */}
            <AppLogoNew style={{
                maxWidth: 400,
                maxHeight: 80,
                width: '100%'
            }} />
        </Box>
        <Typography variant='h2'>
            Website Under Construction
        </Typography>
    </Box>
  )
}

export default MaintenanceLayout