import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';

export default function ConfirmationModal({open, setOpen, handleConfirm, confirmationTitle, confirmationDesc, confirmText, cancleText, data, render  }) {
  const handleClose = (value) => {
    handleConfirm(value, data)
    setOpen(false);
  };
  return (
    <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
      {!!confirmationTitle && <DialogTitle component={'div'} id="alert-dialog-title">
      <Typography variant='h3'>{confirmationTitle}</Typography>
      </DialogTitle>}
      {!!confirmationDesc || !!render? <DialogContent>
          {!!confirmationDesc && <DialogContentText id="alert-dialog-description">
            <Typography variant='p' color={'red'}>{confirmationDesc}</Typography>
          </DialogContentText>}
          {render && render()}
      </DialogContent>: null}
      <DialogActions>
        <CustomButton onClick={()=>handleClose(true)}  sx={{ bgcolor: 'buttonDanger.main', color: 'buttonDanger.contrastText' }} autoFocus>{confirmText}</CustomButton>
        <CustomButton onClick={()=>handleClose(false)}  sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }} autoFocus>{cancleText}</CustomButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.defaultProps = {
    open: false,
    setOpen: ()=>{}, 
    handleConfirm: ()=>{}, 
    confirmationTitle: 'Alert!!', 
    confirmationDesc: "Are you sure you want to perform this action?", 
    confirmText: "Confirm", 
    cancleText: "Cancel",
    render: null
}