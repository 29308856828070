import { CircularProgress } from '@mui/material'
import React from 'react'

function CustomLoading({loading, loadingContainerStyle, loadingStyle, loadingText, loadingFinishText }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "0px", ...loadingContainerStyle }}>
        {loading ? <> <CircularProgress style={{...loadingStyle}}/> {loadingText}</> : loadingFinishText}
      </div>
    </>
  )
}

export default CustomLoading

CustomLoading.defaultProps = {
  loading: false,
  loadingStyle: {},
  loadingText: "",
  loadingFinishText: "No Data Found",
};