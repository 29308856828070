import React from 'react';
import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import CustomBox from '../CommonComp/CustomBox/CustomBox';

let options = {
  chart: {
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  xaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
    borderColor: '#90A4AE',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
};

function SmallBox({ heading, value, percentValue, chartData, chartOptions }) {
  let series = [
    {
      name: heading.split(' ').join(),
      data: chartData || [0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  return (
    <CustomBox padding={2} className="py0">
      <Grid container spacing={2}>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            paddingLeft: '20px',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <Typography variant="p" className='mediumFont' paragraph={true}>
            {heading}
          </Typography>
          <Typography variant="25pxBold4625" paragraph={true}>
            {value}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginTop: '15px',
              color: `${heading === 'Pending Order' ? '#FF5B5B' : '#12B347'}`,
            }}
            paragraph={true}
          >
            {heading === 'Pending Order'
              ? `-${percentValue}`
              : `+${percentValue}`}
          </Typography>
        </Grid>
        <Grid xs={7} item>
          <Chart
            options={{
              ...options,
              chart: {
                ...options.chart,
                id: heading.split(' ').join(),
              },
              ...chartOptions,
            }}
            series={series}
            type="area"
            width={'100%'}
            height={'150'}
          />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default SmallBox;

SmallBox.defaultProps = {
  heading: '',
  value: '',
  percentValue: '',
  chartData: [0, 0, 0, 0, 0, 0, 0, 0],
  chartOptions: {},
};
