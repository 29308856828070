import React from 'react';
import { Button, CircularProgress, Stack, Typography, Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
function CustomButton({ color, variant, disabled, loading, loaderSize, loadingStyle, toolTipTittle, checkBoxEnable, checkBoxLeftText, checkBoxRightText, containerStyle, ...props }) {
  return (
    <>
      {!checkBoxEnable ? <Tooltip title={toolTipTittle}>
        <Button color={color} variant={variant} disabled={disabled || loading} {...props}>
          {props.children} {loading ? <CircularProgress color="inherit" size={loaderSize} style={{marginLeft: 10, ...loadingStyle}} />: null }
        </Button>
      </Tooltip> : <Stack direction="row" spacing={1} alignItems="center" sx={{...containerStyle}}>
        {!!checkBoxLeftText && <Typography variant='h6' >{checkBoxLeftText}</Typography>}
        <Switch {...props}/>
        {!!checkBoxRightText && <Typography variant='h6'>{checkBoxRightText}</Typography>}
      </Stack>}
    </>
  );
}

export default CustomButton;

CustomButton.defaultProps = {
  color: 'buttonNormal',
  variant: 'contained',
  disabled: false,
  loading: false,
  toolTipTittle: '',
  loaderSize: 24,
  checkBoxEnable: false,
  checkBoxLeftText: '',
  checkBoxRightText: '',
  loadingStyle: {},
  containerStyle: {}
};
