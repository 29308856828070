import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  //   pendingOrders: {
  //     error: {
  //       isError: false,
  //       message: '',
  //     },
  //     items: [],
  //   },
  dashboardCount: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboardCounting',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDashboardLoading: (state, action) => {
      state.dashboardCount.loading = action.payload;
    },
    setDashboardCount: (state, action) => {
      state.dashboardCount.items = action.payload;
      state.dashboardCount.error = {
        isError: false,
        message: '',
      };
    },
    setDashboardCountError: (state, action) => {
      state.dashboardCount.items = [];
      state.dashboardCount.error = action.payload;
    },
  },
});

export default dashboardSlice.reducer;

// Action creators are generated for each case reducer function
export const { setLoading, setDashboardCount, setDashboardCountError, setDashboardLoading } =
  dashboardSlice.actions;

export const dashboardItemsSelector = (state) => state.dashboardCount;
