import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    user: {
        error: {
            isError: false,
            message: ""
        },
        userDetails: null,
        userMenu:null
    },
}

export const loginSlice = createSlice({
    name: 'loginUserData',
    initialState,
    reducers: {
        setLoading: (state, action) =>{
            state.loading = action.payload;
        },
        setUserDetails: (state, action) => {
            state.user.userDetails = action.payload;
            state.user.error = {
                isError: false,
                message: ""
            }
        },
        setUserDetailsError: (state, action) => {
            state.user.userDetails = null;
            state.user.error = action.payload
        },
        setUserMenus:(state,action)=>{
            state.user.userMenu = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {setLoading, setUserDetails, setUserDetailsError,setUserMenus } = loginSlice.actions

export default loginSlice.reducer

export const loginItemsSelector = (state) => state.loginUserData;