import React from 'react';
import Box from '@mui/material/Box';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';

function PageNotFound() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = () => logout(null);
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      component="main"
    >
      <CustomBox>
        404 Page Not Found
        <CustomButton
          onClick={() => {
            // handleLogout(); 
            navigate('/')
          }}
          sx={{
            padding: '5px 10px',
            marginLeft: 5,
            fontSize: '12px',
            width: '110px',
            bgcolor: 'buttonInfo.main',
          }}
        >
          Go To Home
        </CustomButton>
      </CustomBox>
    </Box>
  );
}

export default PageNotFound;
